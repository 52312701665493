import {
  createInsertSchema,
  createSelectSchema,
} from "@cloudifybiz/lighthouse-core/db/toZod";

import { availableApp } from "@cloudifybiz/lighthouse-core/db/schema/availableApp";
import { z } from "zod";

const slugCommon = z
  .string()
  .min(2, { message: "Slug needs to be at least 2 characters long" })
  .max(255, { message: "Slug needs to be at most 256 characters long" });

export const slugSchema = slugCommon.regex(
  /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
  "Slug can only contain lowercase letters, numbers, and dashes",
);

export const availableAppInsertSchema = createInsertSchema(availableApp, {
  name: (schema) => schema.name.min(1, "Name is too short"),
  slug: slugSchema,
});

export const availableAppUpdateSchema = availableAppInsertSchema
  .partial()
  .required({
    slug: true,
  });

export const availableAppSelectSchema = createSelectSchema(availableApp);

export const appFieldOptionSchema = z.object({
  label: z.string().min(1),
  value: z.string().min(1),
});

export const appFieldSchema = z
  .object({
    key: z.string(),
    label: z.string(),
    required: z.boolean().default(false),
    description: z.string().optional(),
    defaultValue: z.string().optional(),
    hiddenFromUI: z.boolean().default(false),
    askFromUser: z.boolean().default(true),
  })
  .and(
    z.union([
      z.object({ type: z.literal("text") }),
      z.object({
        type: z.literal("select"),
        options: z.array(appFieldOptionSchema).min(1),
      }),
    ]),
  );

export const authMappingSchema = z.object({
  key: z.string(),
  valuePattern: z.string(),
  type: z.union([z.literal("header"), z.literal("query")]),
});

export const authMappingsSchema = z.array(authMappingSchema).default([]);

export const appFieldsSchema = z.array(appFieldSchema).default([]);
