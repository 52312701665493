export const ROLE = Object.freeze({
  OWNER: "owner",
  EDITOR: "editor",
  VIEWER: "viewer",
});

// Extract the keys from the object
export type Role = (typeof ROLE)[keyof typeof ROLE];

// !IMPORTANT: The order of this array is used to determine the role hierarchy
export const ROLE_VALUES = [ROLE.OWNER, ROLE.EDITOR, ROLE.VIEWER] as const;

export const ROLE_NAMES: Record<Role, string> = Object.freeze({
  [ROLE.OWNER]: "Owner",
  [ROLE.EDITOR]: "Editor",
  [ROLE.VIEWER]: "Viewer",
});

export const TOKEN_TYPE = Object.freeze({
  PUBLISH: "publish",
  SUBSCRIBE: "subscribe",
  VERIFICATION: "verification",
  CONNECTION_STATE: "connection_state",
  CONNECTION_TOKEN_STATE: "connection_token_state",
  CONNECTION_CUSTOM_STATE: "connection_custom_state",
  SESSION: "session",
  INVITE: "invite",
});

export const NOTIFICATION_SUBSCRIPTION_TOKEN_KEY =
  "notificationSubscriptionToken";

export const PUBLIC_IMAGES_PATHS = Object.freeze({
  APP_LOGO: "app_logo/",
  PROFILE: "profile/",
  CONNECTION_GROUP_LOGO: "connection_group/",
  COMPANY_LOGO: "company_logo/",
});

export const AUTHENTICATION_TYPES = Object.freeze({
  OAUTH2: "oauth2",
  CREDENTIALS: "credentials",
  CUSTOM: "custom",
});

export const AUTHENTICATION_TYPE_VALUES = [
  AUTHENTICATION_TYPES.OAUTH2,
  AUTHENTICATION_TYPES.CREDENTIALS,
  AUTHENTICATION_TYPES.CUSTOM,
] as const;

export const AUTHENTICATION_TYPE_NAMES = Object.freeze({
  [AUTHENTICATION_TYPES.OAUTH2]: "OAuth2",
  [AUTHENTICATION_TYPES.CREDENTIALS]: "Credentials",
  [AUTHENTICATION_TYPES.CUSTOM]: "Custom",
});

export const WORKFLOW_RUN_STATUS = Object.freeze({
  PENDING: "pending",
  RUNNING: "running",
  FINISHED: "finished",
  FAILED: "failed",
  SKIPPED: "skipped",
});

export const WORKFLOW_RUN_STATUS_VALUES = [
  WORKFLOW_RUN_STATUS.PENDING,
  WORKFLOW_RUN_STATUS.RUNNING,
  WORKFLOW_RUN_STATUS.FINISHED,
  WORKFLOW_RUN_STATUS.FAILED,
  WORKFLOW_RUN_STATUS.SKIPPED,
] as const;

export const WORKFLOW_RUN_EVENT_TYPE = Object.freeze({
  API_GATEWAY_PROXY_EVENT_V2: "api_gateway_proxy_event_v2",
  OTHER: "other",
});

export const WORKFLOW_RUN_EVENT_TYPE_VALUES = [
  WORKFLOW_RUN_EVENT_TYPE.API_GATEWAY_PROXY_EVENT_V2,
  WORKFLOW_RUN_EVENT_TYPE.OTHER,
] as const;

export const EMAIL_SENDER_TYPES = Object.freeze({
  INVITE_USER: "invite_user",
  ACCOUNT_VERIFICATION: "account_verification",
  FORGOT_PASSWORD: "forgot_password",
  ACCOUNT_TAKEOVER_NOTIFICATION: "account_takeover_notification",
  NEW_USER: "new_user",
  FAILED_WORKFLOW_RUN: "failed_workflow_run",
});

export const EMAIL_SENDER_TYPE_VALUES = [
  EMAIL_SENDER_TYPES.INVITE_USER,
  EMAIL_SENDER_TYPES.ACCOUNT_VERIFICATION,
  EMAIL_SENDER_TYPES.FORGOT_PASSWORD,
  EMAIL_SENDER_TYPES.ACCOUNT_TAKEOVER_NOTIFICATION,
  EMAIL_SENDER_TYPES.NEW_USER,
  EMAIL_SENDER_TYPES.FAILED_WORKFLOW_RUN,
] as const;

export const countries = [
  { text: "Afghanistan", value: "AF" },
  { text: "Åland Islands", value: "AX" },
  { text: "Albania", value: "AL" },
  { text: "Algeria", value: "DZ" },
  { text: "American Samoa", value: "AS" },
  { text: "Andorra", value: "AD" },
  { text: "Angola", value: "AO" },
  { text: "Anguilla", value: "AI" },
  { text: "Antarctica", value: "AQ" },
  { text: "Antigua and Barbuda", value: "AG" },
  { text: "Argentina", value: "AR" },
  { text: "Armenia", value: "AM" },
  { text: "Aruba", value: "AW" },
  { text: "Australia", value: "AU" },
  { text: "Austria", value: "AT" },
  { text: "Azerbaijan", value: "AZ" },
  { text: "Bahamas", value: "BS" },
  { text: "Bahrain", value: "BH" },
  { text: "Bangladesh", value: "BD" },
  { text: "Barbados", value: "BB" },
  { text: "Belarus", value: "BY" },
  { text: "Belgium", value: "BE" },
  { text: "Belize", value: "BZ" },
  { text: "Benin", value: "BJ" },
  { text: "Bermuda", value: "BM" },
  { text: "Bhutan", value: "BT" },
  { text: "Bolivia", value: "BO" },
  { text: "Bosnia and Herzegovina", value: "BA" },
  { text: "Botswana", value: "BW" },
  { text: "Bouvet Island", value: "BV" },
  { text: "Brazil", value: "BR" },
  { text: "British Indian Ocean Territory", value: "IO" },
  { text: "Brunei Darussalam", value: "BN" },
  { text: "Bulgaria", value: "BG" },
  { text: "Burkina Faso", value: "BF" },
  { text: "Burundi", value: "BI" },
  { text: "Cambodia", value: "KH" },
  { text: "Cameroon", value: "CM" },
  { text: "Canada", value: "CA" },
  { text: "Cape Verde", value: "CV" },
  { text: "Cayman Islands", value: "KY" },
  { text: "Central African Republic", value: "CF" },
  { text: "Chad", value: "TD" },
  { text: "Chile", value: "CL" },
  { text: "China", value: "CN" },
  { text: "Christmas Island", value: "CX" },
  { text: "Cocos (Keeling) Islands", value: "CC" },
  { text: "Colombia", value: "CO" },
  { text: "Comoros", value: "KM" },
  { text: "Congo", value: "CG" },
  { text: "Congo, The Democratic Republic of the", value: "CD" },
  { text: "Cook Islands", value: "CK" },
  { text: "Costa Rica", value: "CR" },
  { text: "Cote D'Ivoire", value: "CI" },
  { text: "Croatia", value: "HR" },
  { text: "Cuba", value: "CU" },
  { text: "Cyprus", value: "CY" },
  { text: "Czech Republic", value: "CZ" },
  { text: "Denmark", value: "DK" },
  { text: "Djibouti", value: "DJ" },
  { text: "Dominica", value: "DM" },
  { text: "Dominican Republic", value: "DO" },
  { text: "Ecuador", value: "EC" },
  { text: "Egypt", value: "EG" },
  { text: "El Salvador", value: "SV" },
  { text: "Equatorial Guinea", value: "GQ" },
  { text: "Eritrea", value: "ER" },
  { text: "Estonia", value: "EE" },
  { text: "Ethiopia", value: "ET" },
  { text: "Falkland Islands (Malvinas)", value: "FK" },
  { text: "Faroe Islands", value: "FO" },
  { text: "Fiji", value: "FJ" },
  { text: "Finland", value: "FI" },
  { text: "France", value: "FR" },
  { text: "French Guiana", value: "GF" },
  { text: "French Polynesia", value: "PF" },
  { text: "French Southern Territories", value: "TF" },
  { text: "Gabon", value: "GA" },
  { text: "Gambia", value: "GM" },
  { text: "Georgia", value: "GE" },
  { text: "Germany", value: "DE" },
  { text: "Ghana", value: "GH" },
  { text: "Gibraltar", value: "GI" },
  { text: "Greece", value: "GR" },
  { text: "Greenland", value: "GL" },
  { text: "Grenada", value: "GD" },
  { text: "Guadeloupe", value: "GP" },
  { text: "Guam", value: "GU" },
  { text: "Guatemala", value: "GT" },
  { text: "Guernsey", value: "GG" },
  { text: "Guinea", value: "GN" },
  { text: "Guinea-Bissau", value: "GW" },
  { text: "Guyana", value: "GY" },
  { text: "Haiti", value: "HT" },
  { text: "Heard Island and Mcdonald Islands", value: "HM" },
  { text: "Holy See (Vatican City State)", value: "VA" },
  { text: "Honduras", value: "HN" },
  { text: "Hong Kong", value: "HK" },
  { text: "Hungary", value: "HU" },
  { text: "Iceland", value: "IS" },
  { text: "India", value: "IN" },
  { text: "Indonesia", value: "ID" },
  { text: "Iran, Islamic Republic Of", value: "IR" },
  { text: "Iraq", value: "IQ" },
  { text: "Ireland", value: "IE" },
  { text: "Isle of Man", value: "IM" },
  { text: "Israel", value: "IL" },
  { text: "Italy", value: "IT" },
  { text: "Jamaica", value: "JM" },
  { text: "Japan", value: "JP" },
  { text: "Jersey", value: "JE" },
  { text: "Jordan", value: "JO" },
  { text: "Kazakhstan", value: "KZ" },
  { text: "Kenya", value: "KE" },
  { text: "Kiribati", value: "KI" },
  { text: "Korea, Democratic People'S Republic of", value: "KP" },
  { text: "Korea, Republic of", value: "KR" },
  { text: "Kuwait", value: "KW" },
  { text: "Kyrgyzstan", value: "KG" },
  { text: "Lao People'S Democratic Republic", value: "LA" },
  { text: "Latvia", value: "LV" },
  { text: "Lebanon", value: "LB" },
  { text: "Lesotho", value: "LS" },
  { text: "Liberia", value: "LR" },
  { text: "Libyan Arab Jamahiriya", value: "LY" },
  { text: "Liechtenstein", value: "LI" },
  { text: "Lithuania", value: "LT" },
  { text: "Luxembourg", value: "LU" },
  { text: "Macao", value: "MO" },
  { text: "Macedonia, The Former Yugoslav Republic of", value: "MK" },
  { text: "Madagascar", value: "MG" },
  { text: "Malawi", value: "MW" },
  { text: "Malaysia", value: "MY" },
  { text: "Maldives", value: "MV" },
  { text: "Mali", value: "ML" },
  { text: "Malta", value: "MT" },
  { text: "Marshall Islands", value: "MH" },
  { text: "Martinique", value: "MQ" },
  { text: "Mauritania", value: "MR" },
  { text: "Mauritius", value: "MU" },
  { text: "Mayotte", value: "YT" },
  { text: "Mexico", value: "MX" },
  { text: "Micronesia, Federated States of", value: "FM" },
  { text: "Moldova, Republic of", value: "MD" },
  { text: "Monaco", value: "MC" },
  { text: "Mongolia", value: "MN" },
  { text: "Montserrat", value: "MS" },
  { text: "Morocco", value: "MA" },
  { text: "Mozambique", value: "MZ" },
  { text: "Myanmar", value: "MM" },
  { text: "Namibia", value: "NA" },
  { text: "Nauru", value: "NR" },
  { text: "Nepal", value: "NP" },
  { text: "Netherlands", value: "NL" },
  { text: "Netherlands Antilles", value: "AN" },
  { text: "New Caledonia", value: "NC" },
  { text: "New Zealand", value: "NZ" },
  { text: "Nicaragua", value: "NI" },
  { text: "Niger", value: "NE" },
  { text: "Nigeria", value: "NG" },
  { text: "Niue", value: "NU" },
  { text: "Norfolk Island", value: "NF" },
  { text: "Northern Mariana Islands", value: "MP" },
  { text: "Norway", value: "NO" },
  { text: "Oman", value: "OM" },
  { text: "Pakistan", value: "PK" },
  { text: "Palau", value: "PW" },
  { text: "Palestinian Territory, Occupied", value: "PS" },
  { text: "Panama", value: "PA" },
  { text: "Papua New Guinea", value: "PG" },
  { text: "Paraguay", value: "PY" },
  { text: "Peru", value: "PE" },
  { text: "Philippines", value: "PH" },
  { text: "Pitcairn", value: "PN" },
  { text: "Poland", value: "PL" },
  { text: "Portugal", value: "PT" },
  { text: "Puerto Rico", value: "PR" },
  { text: "Qatar", value: "QA" },
  { text: "Reunion", value: "RE" },
  { text: "Romania", value: "RO" },
  { text: "Russian Federation", value: "RU" },
  { text: "RWANDA", value: "RW" },
  { text: "Saint Helena", value: "SH" },
  { text: "Saint Kitts and Nevis", value: "KN" },
  { text: "Saint Lucia", value: "LC" },
  { text: "Saint Pierre and Miquelon", value: "PM" },
  { text: "Saint Vincent and the Grenadines", value: "VC" },
  { text: "Samoa", value: "WS" },
  { text: "San Marino", value: "SM" },
  { text: "Sao Tome and Principe", value: "ST" },
  { text: "Saudi Arabia", value: "SA" },
  { text: "Senegal", value: "SN" },
  { text: "Serbia and Montenegro", value: "CS" },
  { text: "Seychelles", value: "SC" },
  { text: "Sierra Leone", value: "SL" },
  { text: "Singapore", value: "SG" },
  { text: "Slovakia", value: "SK" },
  { text: "Slovenia", value: "SI" },
  { text: "Solomon Islands", value: "SB" },
  { text: "Somalia", value: "SO" },
  { text: "South Africa", value: "ZA" },
  { text: "South Georgia and the South Sandwich Islands", value: "GS" },
  { text: "Spain", value: "ES" },
  { text: "Sri Lanka", value: "LK" },
  { text: "Sudan", value: "SD" },
  { text: "Suriname", value: "SR" },
  { text: "Svalbard and Jan Mayen", value: "SJ" },
  { text: "Swaziland", value: "SZ" },
  { text: "Sweden", value: "SE" },
  { text: "Switzerland", value: "CH" },
  { text: "Syrian Arab Republic", value: "SY" },
  { text: "Taiwan, Province of China", value: "TW" },
  { text: "Tajikistan", value: "TJ" },
  { text: "Tanzania, United Republic of", value: "TZ" },
  { text: "Thailand", value: "TH" },
  { text: "Timor-Leste", value: "TL" },
  { text: "Togo", value: "TG" },
  { text: "Tokelau", value: "TK" },
  { text: "Tonga", value: "TO" },
  { text: "Trinidad and Tobago", value: "TT" },
  { text: "Tunisia", value: "TN" },
  { text: "Turkey", value: "TR" },
  { text: "Turkmenistan", value: "TM" },
  { text: "Turks and Caicos Islands", value: "TC" },
  { text: "Tuvalu", value: "TV" },
  { text: "Uganda", value: "UG" },
  { text: "Ukraine", value: "UA" },
  { text: "United Arab Emirates", value: "AE" },
  { text: "United Kingdom", value: "GB" },
  { text: "United States", value: "US" },
  { text: "United States Minor Outlying Islands", value: "UM" },
  { text: "Uruguay", value: "UY" },
  { text: "Uzbekistan", value: "UZ" },
  { text: "Vanuatu", value: "VU" },
  { text: "Venezuela", value: "VE" },
  { text: "Viet Nam", value: "VN" },
  { text: "Virgin Islands, British", value: "VG" },
  { text: "Virgin Islands, U.S.", value: "VI" },
  { text: "Wallis and Futuna", value: "WF" },
  { text: "Western Sahara", value: "EH" },
  { text: "Yemen", value: "YE" },
  { text: "Zambia", value: "ZM" },
  { text: "Zimbabwe", value: "ZW" },
];

// Reference: https://github.com/colinhacks/zod/issues/2376
export const countriesEnum = countries.map(({ value }) => value) as [
  string,
  ...string[],
];

export const locales = [
  { text: "Anguilla", value: "en-AI" },
  { text: "Antigua and Barbuda", value: "en-AG" },
  { text: "Australia", value: "en-AU" },
  { text: "Bahamas", value: "en-BS" },
  { text: "Barbados", value: "en-BB" },
  { text: "Belize", value: "en-BZ" },
  { text: "Bermuda", value: "en-BM" },
  { text: "Botswana", value: "en-BW" },
  { text: "British Indian Ocean Territory", value: "en-IO" },
  { text: "British Virgin Islands", value: "en-VG" },
  { text: "Cameroon", value: "en-CM" },
  { text: "Canada", value: "en-CA" },
  { text: "Cayman Islands", value: "en-KY" },
  { text: "Cook Islands", value: "en-CK" },
  { text: "Denmark", value: "en-DK" },
  { text: "Dominica", value: "en-DM" },
  { text: "Eswatini", value: "en-SZ" },
  { text: "Falkland Islands", value: "en-FK" },
  { text: "Fiji", value: "en-FJ" },
  { text: "Gambia", value: "en-GM" },
  { text: "Ghana", value: "en-GH" },
  { text: "Gibraltar", value: "en-GI" },
  { text: "Grenada", value: "en-GD" },
  { text: "Guam", value: "en-GU" },
  { text: "Guyana", value: "en-GY" },
  { text: "India", value: "en-IN" },
  { text: "Ireland", value: "en-IE" },
  { text: "Jamaica", value: "en-JM" },
  { text: "Kenya", value: "en-KE" },
  { text: "Kiribati", value: "en-KI" },
  { text: "Lesotho", value: "en-LS" },
  { text: "Liberia", value: "en-LR" },
  { text: "Malawi", value: "en-MW" },
  { text: "Malaysia", value: "en-MY" },
  { text: "Malta", value: "en-MT" },
  { text: "Marshall Islands", value: "en-MH" },
  { text: "Mauritius", value: "en-MU" },
  { text: "Montserrat", value: "en-MS" },
  { text: "Namibia", value: "en-NA" },
  { text: "Nauru", value: "en-NR" },
  { text: "New Zealand", value: "en-NZ" },
  { text: "Nigeria", value: "en-NG" },
  { text: "Niue", value: "en-NU" },
  { text: "Norfolk Island", value: "en-NF" },
  { text: "Northern Mariana Islands", value: "en-MP" },
  { text: "Pakistan", value: "en-PK" },
  { text: "Palau", value: "en-PW" },
  { text: "Papua New Guinea", value: "en-PG" },
  { text: "Philippines", value: "en-PH" },
  { text: "Pitcairn Islands", value: "en-PN" },
  { text: "Puerto Rico", value: "en-PR" },
  { text: "Rwanda", value: "en-RW" },
  { text: "Saint Kitts and Nevis", value: "en-KN" },
  { text: "Saint Lucia", value: "en-LC" },
  { text: "Saint Vincent and the Grenadines", value: "en-VC" },
  { text: "Samoa", value: "en-WS" },
  { text: "Seychelles", value: "en-SC" },
  { text: "Sierra Leone", value: "en-SL" },
  { text: "Singapore", value: "en-SG" },
  { text: "Solomon Islands", value: "en-SB" },
  { text: "South Africa", value: "en-ZA" },
  { text: "South Georgia and the South Sandwich Islands", value: "en-GS" },
  { text: "South Sudan", value: "en-SS" },
  { text: "Sri Lanka", value: "en-LK" },
  { text: "Suriname", value: "en-SR" },
  { text: "Swaziland", value: "en-SZ" },
  { text: "Tanzania", value: "en-TZ" },
  { text: "Tonga", value: "en-TO" },
  { text: "Trinidad and Tobago", value: "en-TT" },
  { text: "Turks and Caicos Islands", value: "en-TC" },
  { text: "Tuvalu", value: "en-TV" },
  { text: "Uganda", value: "en-UG" },
  { text: "United Kingdom", value: "en-GB" },
  { text: "United States", value: "en-US" },
  { text: "United States Minor Outlying Islands", value: "en-UM" },
  { text: "Virgin Islands, British", value: "en-VG" },
  { text: "Virgin Islands, U.S.", value: "en-VI" },
  { text: "Wallis and Futuna", value: "en-WF" },
  { text: "Western Sahara", value: "en-EH" },
  { text: "Yemen", value: "en-YE" },
  { text: "Zambia", value: "en-ZM" },
  { text: "Zimbabwe", value: "en-ZW" },
  { text: "Bahrain", value: "en-BH" },
  { text: "Bangladesh", value: "en-BD" },
  { text: "Bhutan", value: "en-BT" },
  { text: "Brunei", value: "en-BN" },
  { text: "Cyprus", value: "en-CY" },
  { text: "Eritrea", value: "en-ER" },
  { text: "Guyana", value: "en-GY" },
  { text: "Iran", value: "en-IR" },
  { text: "Iraq", value: "en-IQ" },
  { text: "Jordan", value: "en-JO" },
  { text: "Kuwait", value: "en-KW" },
  { text: "Lebanon", value: "en-LB" },
  { text: "Libya", value: "en-LY" },
  { text: "Maldives", value: "en-MV" },
  { text: "Myanmar (Burma)", value: "en-MM" },
  { text: "Nepal", value: "en-NP" },
  { text: "Oman", value: "en-OM" },
  { text: "Qatar", value: "en-QA" },
  { text: "Somalia", value: "en-SO" },
  { text: "Sudan", value: "en-SD" },
  { text: "Syria", value: "en-SY" },
  { text: "Timor-Leste", value: "en-TL" },
  { text: "United Arab Emirates", value: "en-AE" },
  { text: "Yemen", value: "en-YE" },
];

export const localesEnum = locales.map((locale) => locale.value) as [
  string,
  ...string[],
];

export const expirationOptions = Object.freeze([
  "7 Days",
  "30 Days",
  "60 Days",
  "90 Days",
  "Never Expire",
]);

export const PROSPECT_STATUS = Object.freeze({
  CREATED: "created",
  MEETING_BOOKED: "meeting_booked",
  MEETING_COMPLETED: "meeting_completed",
  IMPLEMENTATION: "implementation",
  COMPLETED: "completed",
});

export const PROSPECT_STATUS_VALUES = [
  PROSPECT_STATUS.CREATED,
  PROSPECT_STATUS.MEETING_BOOKED,
  PROSPECT_STATUS.MEETING_COMPLETED,
  PROSPECT_STATUS.IMPLEMENTATION,
  PROSPECT_STATUS.COMPLETED,
] as const;

export const PROSPECT_STATUS_NAMES = Object.freeze({
  [PROSPECT_STATUS.CREATED]: "Created",
  [PROSPECT_STATUS.MEETING_BOOKED]: "Meeting Booked",
  [PROSPECT_STATUS.MEETING_COMPLETED]: "Meeting Completed",
  [PROSPECT_STATUS.IMPLEMENTATION]: "Implementation",
  [PROSPECT_STATUS.COMPLETED]: "Completed",
});

export const USER_NOTIFIER_TYPES = {
  FAILED_WORKFLOW_RUN: "failed_workflow_run",
} as const;

export const USER_NOTIFIER_TYPE_VALUES = [
  USER_NOTIFIER_TYPES.FAILED_WORKFLOW_RUN,
] as const;
