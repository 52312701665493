import { relations, sql } from "drizzle-orm";
import { mysqlTable, text, timestamp, varchar } from "drizzle-orm/mysql-core";

import { availableConnection } from "./availableConnection";

export const availableConnectionGroup = mysqlTable(
  "available_connection_group",
  {
    slug: varchar("slug", { length: 256 }).primaryKey(),
    name: varchar("name", { length: 256 }).notNull(),
    description: text("description"),
    icon: varchar("icon", { length: 256 }).notNull(),
    createdAt: timestamp("created_at")
      .default(sql`CURRENT_TIMESTAMP`)
      .notNull(),
    updatedAt: timestamp("updated_at")
      .default(sql`CURRENT_TIMESTAMP`)
      .onUpdateNow()
      .notNull(),
  },
);

export const availableConnectionGroupRelations = relations(
  availableConnectionGroup,
  ({ many }) => ({
    availableConnections: many(availableConnection),
  }),
);
