import { relations, sql } from "drizzle-orm";
import {
  json,
  mysqlTable,
  varchar,
  text,
  timestamp,
} from "drizzle-orm/mysql-core";

import { availableConnection } from "./availableConnection";

export const availableApp = mysqlTable("available_app", {
  slug: varchar("slug", { length: 256 }).primaryKey(),
  name: varchar("name", { length: 256 }).notNull(),
  description: text("description"),
  icon: varchar("icon", { length: 256 }).notNull(),
  fields: json("fields"),
  createdAt: timestamp("created_at")
    .default(sql`CURRENT_TIMESTAMP`)
    .notNull(),
  updatedAt: timestamp("updated_at")
    .default(sql`CURRENT_TIMESTAMP`)
    .onUpdateNow()
    .notNull(),
});

export const availableAppRelation = relations(availableApp, ({ one }) => ({
  availableConnections: one(availableConnection, {
    fields: [availableApp.slug],
    references: [availableConnection.availableAppSlug],
  }),
}));
