/**
 * @description Get title formatted string
 * @example
 * // Returns "Hello World"
 * getTitleFormattedString("hello_world")
 * @example
 * // Returns "Hello World"
 * getTitleFormattedString("hello-world")
 * @example
 * // Returns "Hello World"
 * getTitleFormattedString("Hello World")
 * @example
 * // Returns "Hello World"
 * getTitleFormattedString("helloWorld")
 * @example
 * // Returns "Hello World"
 * getTitleFormattedString("hello world")
 * @param string - The string to format
 * @returns The formatted string
 */
export const getTitleFormattedString = (string: string) => {
  // Replace underscores and dashes with spaces, separate camel case, then capitalize the first letter of each word
  return string
    .replace(/[_-]/g, " ")
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
};
