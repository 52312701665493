import { relations, sql } from "drizzle-orm";
import {
  mysqlTable,
  mysqlEnum,
  varchar,
  json,
  timestamp,
  primaryKey,
} from "drizzle-orm/mysql-core";

import { availableConnectionGroup } from "./availableConnectionGroup";
import { availableApp } from "./availableApp";
import { AUTHENTICATION_TYPE_VALUES } from "../../constants";

export const availableConnection = mysqlTable(
  "available_connection",
  {
    slug: varchar("slug", { length: 256 }).notNull(),
    name: varchar("name", { length: 256 }).notNull(),
    availableAppSlug: varchar("available_app_slug", { length: 256 }).notNull(),
    availableConnectionGroupSlug: varchar("available_connection_group_slug", {
      length: 256,
    }),
    fields: json("fields"),
    authType: mysqlEnum("auth_type", AUTHENTICATION_TYPE_VALUES).notNull(),
    link: varchar("link", { length: 2048 }),
    baseUrl: varchar("base_url", { length: 2048 }),
    authOptions: json("auth_options"),
    authMapping: json("auth_mapping"),
    createdAt: timestamp("created_at")
      .default(sql`CURRENT_TIMESTAMP`)
      .notNull(),
    updatedAt: timestamp("updated_at")
      .default(sql`CURRENT_TIMESTAMP`)
      .onUpdateNow()
      .notNull(),
  },
  (t) => ({
    pk: primaryKey(t.slug, t.availableAppSlug),
  }),
);

export const availableConnectionRelation = relations(
  availableConnection,
  ({ one }) => ({
    availableConnectionGroup: one(availableConnectionGroup, {
      fields: [availableConnection.availableConnectionGroupSlug],
      references: [availableConnectionGroup.slug],
    }),
    availableApp: one(availableApp, {
      fields: [availableConnection.availableAppSlug],
      references: [availableApp.slug],
    }),
  }),
);
